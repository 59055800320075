import BrowserDatabase from 'Util/BrowserDatabase';

import { UPDATE_CONFIG, UPDATE_CONFIG_DEVICE } from './Config.action';

export const MAX_WIDTH = 150;
export const MAX_HEIGHT = 40;

export const filterStoreConfig = (config) => Object.entries(config).reduce(
    (acc, [key, value]) => (value !== null ? { ...acc, [key]: value } : acc),
    {}
);

const {
    countries,
    reviewRatings,
    storeConfig,
    gtm,
    freeShipping,
    promoData,
    cms_LIO,
    cartRuleSpecialPrice,
    newsletter,
    sticker_notifications,
    featureList,
    getPopupInformation,
    getEasyBoxAllowCod,
    getExcludedProducts,
    getPointsExcludedProducts,
    getCmsBlockNotificationId,
    fidelizare_config,
    lottery_config,
    getOgImageUrl,
    promoItemsForPopup
} = BrowserDatabase.getItem('config') || {
    countries: [],
    reviewRatings: [],
    storeConfig: {},
    gtm: {},
    freeShipping: {
        threshold: null,
        customer_message: '',
        guest_message: ''
    },
    promoData : {},
    cms_LIO : {},
    cartRuleSpecialPrice : {},
    newsletter: {},
    sticker_notifications : {},
    featureList: [],
    getEasyBoxAllowCod,
    getCmsBlockNotificationId,
    getPopupInformation: {
        enabled: false,
        block_id: '',
        popup_timer: '',
        popup_lifetime: ''
    },
    clickandcollect : {},
    getExcludedProducts,
    getPointsExcludedProducts,
    fidelizare_config: {},
    lottery_config: {},
    getOgImageUrl,
    promoItemsForPopup: {}
};

export const initialState = {
    ...filterStoreConfig(storeConfig),
    countries,
    reviewRatings,
    checkoutAgreements: [],
    isLoading: true,
    gtm,
    freeShipping,
    promoData,
    cms_LIO,
    cartRuleSpecialPrice,
    newsletter,
    sticker_notifications,
    featureList,
    getEasyBoxAllowCod,
    getPopupInformation,
    getCmsBlockNotificationId,
    fidelizare_config,
    lottery_config,
    promoItemsForPopup,
    getExcludedProducts: [],
    getPointsExcludedProducts,
    getOgImageUrl,
    device: {
        isMobile: true,
        android: true,
        ios: false,
        blackberry: false,
        opera: false,
        windows: false,
        standaloneMode: window.matchMedia('(display-mode: standalone)').matches
    }
};

const ConfigReducer = (state = initialState, action) => {
    const {
        type,
        config: {
            gtm,
            countries,
            reviewRatings,
            checkoutAgreements,
            storeConfig = {},
            customerAdditionalAttributes,
            freeShipping,
            promoData,
            cms_LIO,
            cartRuleSpecialPrice,
            newsletter,
            sticker_notifications,
            featureList,
            getEasyBoxAllowCod,
            getPopupInformation,
            getExcludedProducts,
            getPointsExcludedProducts,
            getCmsBlockNotificationId,
            fidelizare_config,
            lottery_config,
            getOgImageUrl,
            promoItemsForPopup
        } = {},
        device
    } = action;

    switch (type) {
    case UPDATE_CONFIG:
        const filteredStoreConfig = filterStoreConfig(storeConfig);

        return {
            ...state,
            countries,
            reviewRatings,
            customerAdditionalAttributes,
            checkoutAgreements,
            gtm,
            freeShipping,
            promoData,
            cms_LIO,
            cartRuleSpecialPrice,
            newsletter,
            sticker_notifications,
            featureList,
            getEasyBoxAllowCod,
            getPopupInformation,
            ...filteredStoreConfig,
            getExcludedProducts,
            getPointsExcludedProducts,
            getCmsBlockNotificationId,
            fidelizare_config,
            lottery_config,
            getOgImageUrl,
            promoItemsForPopup,
            // Should be updated manually as filteredStoreConfig does not contain header_logo_src when it is null
            // and header_logo_src takes old value
            isLoading: false
        };

    case UPDATE_CONFIG_DEVICE:
        return {
            ...state,
            device: {
                ...state.device,
                ...device
            }
        };

    default:
        return state;
    }
};

export default ConfigReducer;
