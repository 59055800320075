import {
    combineReducers,
    createStore,
    compose
} from 'redux';

import CategoryReducer from 'Store/Category/Category.reducer';
import NotificationReducer from 'Store/Notification/Notification.reducer';
import BreadcrumbsReducer from 'Store/Breadcrumbs/Breadcrumbs.reducer';
import ProductReducer from 'Store/Product/Product.reducer';
import ProductListReducer from 'Store/ProductList/ProductList.reducer';
import ProductListInfoReducer from 'Store/ProductListInfo/ProductListInfo.reducer';
import CartReducer from 'Store/Cart/Cart.reducer';
import OrderReducer from 'Store/Order/Order.reducer';
import WishlistReducer from 'Store/Wishlist/Wishlist.reducer';
import NoMatchReducer from 'Store/NoMatch/NoMatch.reducer';
import SearchBarReducer from 'Store/SearchBar/SearchBar.reducer';
import UrlRewritesReducer from 'Store/UrlRewrites/UrlRewrites.reducer';
import MyAccountReducer from 'Store/MyAccount/MyAccount.reducer';
import NavigationReducer from 'Store/Navigation/Navigation.reducer';
import OverlayReducer from 'Store/Overlay/Overlay.reducer';
import OfflineReducer from 'Store/Offline/Offline.reducer';
import PopupReducer from 'Store/Popup/Popup.reducer';
import ConfigReducer from 'Store/Config/Config.reducer';
import MetaReducer from 'Store/Meta/Meta.reducer';
import LinkedProductsReducer from 'Store/LinkedProducts/LinkedProducts.reducer';
import QuestionReducer from 'Store/Question/Question.reducer';
import ReviewsReducer from 'Store/Reviews/Reviews.reducer';
import BannersReducer from 'Store/Banners/Banners.reducer';
import BlogReducer from 'Store/Blog/Blog.reducer';
import PromotionsReducer from 'Store/Promotions/Promotions.reducer';
import PromotionReducer from 'Store/Promotion/Promotion.reducer';
import SocialLoginReducer from 'Store/SocialLogins/SocialLogin.reducer';
import CheckoutReducer from 'Store/Checkout/Checkout.reducer';
import BuyTogetherReducer from 'Store/BuyTogether/BuyTogether.reducer';

export const staticReducers = {
    CategoryReducer,
    NotificationReducer,
    BreadcrumbsReducer,
    ProductReducer,
    ProductListReducer,
    ProductListInfoReducer,
    CartReducer,
    OrderReducer,
    WishlistReducer,
    NoMatchReducer,
    SearchBarReducer,
    MyAccountReducer,
    NavigationReducer,
    OverlayReducer,
    OfflineReducer,
    PopupReducer,
    UrlRewritesReducer,
    ConfigReducer,
    MetaReducer,
    LinkedProductsReducer,
    QuestionReducer,
    ReviewsReducer,
    BannersReducer,
    BlogReducer,
    PromotionsReducer,
    PromotionReducer,
    SocialLoginReducer,
    CheckoutReducer,
    BuyTogetherReducer
};

export function createReducer(asyncReducers) {
    return combineReducers({
        ...staticReducers,
        ...asyncReducers
    });
}

import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
});

const composeEnhancers = ( process.env.NODE_ENV === 'developasdment' && window.__REDUX_DEVTOOLS_EXTENSION__ ) && window.__REDUX_DEVTOOLS_EXTENSION__({
    trace: true
}) || compose;

const store = createStore(
    createReducer(),
    compose(composeEnhancers, sentryReduxEnhancer)
);

// Configure the store
export default function configureStore() {
    // Add a dictionary to keep track of the registered async reducers
    store.asyncReducers = {};

    // Create an inject reducer function
    // This function adds the async reducer, and creates a new combined reducer
    store.injectReducer = (key, asyncReducer) => {
        store.asyncReducers[key] = asyncReducer;
        store.replaceReducer(createReducer(store.asyncReducers));
    };

    // Return the modified store
    return store;
}

export function getStore() {
    return store;
}
